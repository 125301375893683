import { useRef, useEffect } from 'react';
import { throttle } from 'helpers/utilities';

const useThrottle = () => {
  const throttles = useRef([]);

  useEffect(
    () => () => {
      throttles.current.forEach(fn => fn.cancel());
      throttles.current = [];
    },
    []
  );

  return (cb, delay) => {
    const flush = throttle(cb, delay);
    throttles.current.push(flush);
    return flush;
  };
};

export default useThrottle;
