import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import Swipe from 'react-easy-swipe';
import { get } from 'helpers/utilities';

import EventHandler from 'helpers/EventHandler';
import { EVENT, breakpoints } from 'config/constants';
import usePrevious from '../../hooks/usePrevious';
import useBreakpoint from '../../hooks/useBreakpoint';
import useTimeout from '../../hooks/useTimeout';
import useThrottle from '../../hooks/useThrottle';

import styles from './styles.scss';

const BackButton = ({ onClick, customStyle, currentSlide }) => {
  const notActive = currentSlide === 0;
  return (
    <button
      type="button"
      className={`${styles.eventsModule__control} ${styles.eventsModule__control_left}`}
      onClick={onClick}
      aria-label="Previous Slide"
      aria-hidden={notActive}
      style={{ cursor: notActive ? 'auto' : 'pointer' }}
      disabled={notActive}>
      <span style={customStyle} />
    </button>
  );
};

const NextButton = ({ onClick, customStyle, currentSlide, slideCount }) => {
  const notActive = currentSlide === slideCount - 3;
  return (
    <button
      type="button"
      className={`${styles.eventsModule__control} ${styles.eventsModule__control_right}`}
      onClick={onClick}
      aria-label="Next Slide"
      aria-hidden={currentSlide === slideCount - 3}
      style={{ cursor: notActive ? 'auto' : 'pointer' }}
      disabled={notActive}>
      <span style={customStyle} />
    </button>
  );
};

const EventsModule = props => {
  const {
    data: { eventsLinks },
    stickyRef
  } = props;

  const timeout = useTimeout();
  const throttle = useThrottle();
  const [isFirst, setIsFirst] = useState(true);
  const [isLast, setIsLast] = useState(false);
  const [height, setHeight] = useState(null);
  const [isReady, setIsReady] = useState(!stickyRef);
  const breakpoint = useBreakpoint();

  const eventResize = useRef(null);
  const sliderRef = useRef();

  const settings = {
    accessibility: true,
    centerPadding: '32px',
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextButton />,
    prevArrow: <BackButton />,
    beforeChange: changeHandler,
    afterChange
  };

  let originalX = 0;
  let currentX = 0;
  let index = 0;
  let scrollDirection = null;

  const validEvents = eventsLinks.filter(event =>
    Boolean(
      get(event, 'internalUrl[0].leftRailIntroDetails', []).find(c =>
        Boolean(c.startDateAndTime)
      ) || get(event, 'eventDate')
    )
  );

  let direction = 1;

  // Previous states to emulate shouldComponentUpdate
  const previousBreakpoint = usePrevious(breakpoint);
  const previousIsReady = usePrevious(isReady);

  // componentDidMount
  useEffect(() => {
    if (!sliderRef.current) return;

    if (!stickyRef) {
      eventResize.current = EventHandler.subscribe(EVENT.RESIZE, throttle(resizeHandler, 500));
      timeout(() => {
        if (sliderRef && sliderRef.current) {
          setHeight(sliderRef.current.offsetHeight);
        }
      }, 100);
    }

    timeout(() => setIsReady(true), 2000);

    // Cleanup
    return cleanup;
  }, []);

  // Emulates shouldComponentUpdate
  useEffect(() => {
    if (previousBreakpoint !== breakpoint) {
      setIsReady(false);
      timeout(() => {
        setIsReady(true);
      }, 2000);
    }
  }, [breakpoint]);

  useEffect(() => {
    if (previousIsReady !== isReady) {
      eventResize.current = EventHandler.subscribe(EVENT.RESIZE, throttle(resizeHandler, 500));

      timeout(() => {
        if (sliderRef.current) {
          setHeight(sliderRef.current.offsetHeight);
        }
      }, 100);
    }

    return cleanup;
  }, [isReady]);

  function cleanup() {
    if (eventResize.current) EventHandler.unsubscribe(eventResize.current);
  }

  function resizeHandler(_, e) {
    if (e.width >= breakpoints.smallDesktop && sliderRef.current) {
      sliderRef.current.classList.remove(`${styles.eventsModule__cards}--swipe`);
      sliderRef.current.style.transform = '';
    } else {
      goToCard(index);
    }
  }

  function onSwipeStart() {
    if (window.innerWidth >= breakpoints.smallDesktop || !sliderRef.current) {
      return;
    }

    originalX = currentTranslateX();
    currentX = 0;

    sliderRef.current.classList.remove(`${styles.eventsModule__cards}--swipe`);
  }

  function onSwipeMove(e) {
    if (window.innerWidth >= breakpoints.smallDesktop || !sliderRef.current) {
      return false;
    }

    if (!scrollDirection) {
      // threshold of 5 to determine
      // what scroll direction
      if (e.y > 5 || e.y < -5) {
        scrollDirection = 'vertical';
      } else {
        scrollDirection = 'horizontal';
      }
    }

    if (scrollDirection === 'horizontal') {
      const lastIndex = breakpoint === 'tablet' ? index + 1 : index;

      currentX = e.x;
      let newX = e.x + originalX;
      if ((index === 0 && e.x > 0) || (lastIndex === validEvents.length - 1 && e.x < 0)) {
        newX = originalX;
      }
      sliderRef.current.style.transform = `translateX(${newX}px)`;
      return true;
    }
    return false;
  }

  function onSwipeEnd() {
    scrollDirection = null;
    if (window.innerWidth >= breakpoints.smallDesktop || !sliderRef.current) {
      return;
    }

    const direction = currentX > 0 ? -1 : 1;

    sliderRef.current.classList.add(`${styles.eventsModule__cards}--swipe`);

    if (Math.abs(currentX) > window.innerWidth / 4) {
      goToCard((index += direction));
    } else {
      goToCard(index);
    }
  }

  function goToCard(index = 0) {
    if (!sliderRef.current) return;

    const lastMargin =
      breakpoint === 'tablet'
        ? ((cardWidth() - currentMargin()) * 2) / 5
        : ((cardWidth() - currentMargin()) * 1) / 5;
    const id = currentIndex(index);
    index = id;
    const realId = breakpoint === 'tablet' ? id + 1 : id;
    originalX = (id * cardWidth() + (realId === validEvents.length - 1 ? -lastMargin : 0)) * -1;
    sliderRef.current.style.transform = `translateX(${originalX}px)`;
  }

  function currentIndex(index = 0) {
    let card = 0;

    if (index < 0) {
      card = 0;
    } else if (index > validEvents.length - 1) {
      card = validEvents.length - 1;
    } else if (window.innerWidth >= breakpoints.tablet && index >= validEvents.length - 1) {
      card = index - 1;
    } else {
      card = index;
    }

    return card;
  }

  function changeHandler(oldIndex, newIndex) {
    direction = oldIndex < newIndex ? 1 : -1;

    if (breakpoint === 'mobile' || breakpoint === ' tablet') return;

    if (newIndex === 0 && !isFirst) {
      timeout(() => {
        setIsFirst(true);
        setIsLast(false);
      }, 600);
    } else if (newIndex + 2 === validEvents.length - 1) {
      timeout(() => {
        setIsLast(true);
        setIsFirst(false);
      }, 600);
    } else if (isFirst || isLast) {
      timeout(() => {
        setIsFirst(false);
        setIsLast(false);
      }, 600);
    }
  }

  function afterChange(currentIndex) {
    const sliderEl = sliderRef.current;
    if (!sliderEl) return;
    const index = direction === 1 ? currentIndex + 2 : currentIndex;
    const slide = document.querySelector(`div[data-index="${index}"]`);
    if (!slide) return;
    slide.focus();
  }

  function isSwipeActive(data) {
    if (validEvents.length < 3 && breakpoint === 'tablet') {
      return (
        <div className={styles.eventsModule__cards__container}>
          <div
            className={`${styles.eventsModule__cards} ${styles.eventsModule__cards}--no-swipe`}
            ref={sliderRef}>
            {data.map((d, i) => renderEventCard(d, i))}
          </div>
        </div>
      );
    }
    return (
      <Swipe
        onSwipeStart={onSwipeStart}
        onSwipeMove={onSwipeMove}
        onSwipeEnd={onSwipeEnd}
        className={styles.eventsModule__cards__container}>
        <div className={styles.eventsModule__cards} ref={sliderRef}>
          {data.map((d, i) => renderEventCard(d, i))}
        </div>
      </Swipe>
    );
  }

  function renderEventCard(data, i) {
    const lrid = get(data, 'internalUrl[0].leftRailIntroDetails');
    let eventDetailsFromLrid;
    if (lrid) {
      eventDetailsFromLrid = lrid.find(c =>
        Boolean(c.whatLabel || c.whereLabel || c.startDateAndTime)
      );
    }
    const timestamp = get(eventDetailsFromLrid, 'startDateAndTime') || get(data, 'eventDate');
    if (!timestamp) return null;

    const date = new Date(timestamp * 1000);
    const content = {};
    content.month = date.toLocaleString('dfault', { month: 'short' }).toUpperCase();
    content.day = date.getDate();
    content.title = get(data, 'internalUrl[0].title') || get(data, 'eventTitle');
    content.eventName = get(eventDetailsFromLrid, 'whatLabel') || get(data, 'eventName');
    content.location = get(eventDetailsFromLrid, 'whereLabel') || get(data, 'eventLocation');
    content.link = get(data, 'internalUrl[0].uri') || get(data, 'externalUrl');
    content.isInternal = get(data, 'internalUrl');

    return content.isInternal || (!content.isInternal && !content.link) ? (
      <div key={i} className={styles.eventsModule__card} aria-label={`Slide ${i + 1}`}>
        <div style={{ height: height || '' }} className={styles.eventsModule__card__wrapper}>
          {renderEventContent(content)}
        </div>
      </div>
    ) : (
      <div key={i} className={styles.eventsModule__card}>
        <a href={content.link} tabIndex="0" aria-label={`Slide ${i + 1}`}>
          <div style={{ height: height || '' }} className={styles.eventsModule__card__wrapper}>
            {renderEventContent(content)}
          </div>
        </a>
      </div>
    );
  }

  function renderEventContent({ month, day, title, eventName, location, link, isInternal }) {
    return (
      <>
        <p className={styles.eventsModule__card__date}>
          {month}
          <span>{day}</span>
        </p>
        <p className={styles.eventsModule__card__title}>{title}</p>
        {eventName && <p className={styles.eventsModule__card__event}>{eventName}</p>}
        {location && <p className={styles.eventsModule__card__place}>{location}</p>}
        {link && isInternal && (
          <Link
            to={{ pathname: `/${link}` }}
            className={styles.eventsModule__card__cta}
            title="Learn More">
            Learn More
          </Link>
        )}
      </>
    );
  }

  function renderCardsContainer(data) {
    return breakpoint === 'mobile' || breakpoint === 'tablet' ? (
      isSwipeActive(data)
    ) : (
      <div
        ref={sliderRef}
        className={styles.eventsModule__cards__container}
        aria-label={`${data.length} slides carousel`}>
        {isReady && (
          <Slider
            {...settings}
            className={`${styles.eventsModule__cards} ${
              data.length <= 3 ? styles.eventsModule__cards_few : ''
            }`}>
            {data.map((d, i) => renderEventCard(d, i))}
          </Slider>
        )}
      </div>
    );
  }

  function currentTranslateX() {
    const currentTransform = window.getComputedStyle(sliderRef.current).transform;
    return currentTransform !== 'none' ? parseFloat(currentTransform.match(/[0-9-.]+/g)[4]) : 0;
  }

  function cardWidth() {
    if (sliderRef.current) {
      return sliderRef.current.children[0].clientWidth + currentMargin(); // 2 for the border
    }

    return 0;
  }

  function currentMargin() {
    if (!sliderRef.current || !sliderRef.current.children[1]) return 0;
    const _currentMargin = window.getComputedStyle(sliderRef.current.children[1]).marginLeft;
    return _currentMargin !== '0px' ? parseFloat(_currentMargin) : 0;
  }

  return (
    validEvents.length > 0 && (
      <section
        style={{ height: !isReady ? '500px' : '' }}
        ref={stickyRef}
        className={`${styles.eventsModule} ${isFirst ? styles.eventsModule__first : ''} ${
          isLast ? styles.eventsModule__last : ''
        }`}>
        <div className="wrapper grid-container">
          <h3 className={styles.eventsModule__title}>
            <strong>Featured</strong> Events
          </h3>
          {renderCardsContainer(validEvents)}
        </div>
      </section>
    )
  );
};

export default EventsModule;
