import React from 'react';
import { includes } from 'helpers/utilities';
import Card from 'components/Cards';
import Intersect from 'components/Intersect';

import { flipProp } from 'helpers/fp';

import styles from './styles.scss';

const getCardGridClassName = length =>
  flipProp({
    specialized1: `${styles.special_card_grid__specialized1} ${
      styles[`special_card_grid__specialized1--${length}`]
    }`,
    specialized2: `${styles.special_card_grid__specialized2} ${
      styles[`special_card_grid__specialized2--${length}`]
    }`,
    simplified: styles.special_card_grid__simplified
  });

const extractCards = cards =>
  flipProp({
    // [specialized card, simplified card]
    specialized1: [cards.slice(0, 7), cards.slice(7)],
    specialized2: [cards.slice(0, 8), cards.slice(8)],
    simplified: [[], cards]
  });

// use for mapping cards that is showned, used to create a delay
const toBeShownCards = {};

const onCardIntersection = (entries, observe, id) => {
  entries.forEach(entry => {
    if (entry.intersectionRatio) {
      toBeShownCards[id] = true;
      const delay = Object.keys(toBeShownCards).length * 50;
      window.requestAnimationFrame(() => {
        Object.assign(entry.target.style, {
          transition: `0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) ${delay}ms`
        });
        entry.target.classList.add(styles.special_card_grid__animated_visible);
      });
      observe.unobserve(entry.target);
    }
  });
};

const getCard = ({ featured, card, animated, index, transparentCard, zoomCard }) => (
  <Intersect
    onIntersection={(...params) => onCardIntersection(...params, card.uri)}
    active={animated}
    key={index}>
    <Card
      {...card}
      size={featured ? 'featured' : 'standard'}
      transparentCard={transparentCard}
      zoom={zoomCard}
      className={[
        featured
          ? styles.special_card_grid__card_featured
          : styles.special_card_grid__card_standard,
        animated ? styles.special_card_grid__animated : ''
      ].join(' ')}
      onTransitionEndCapture={() => delete toBeShownCards[card.uri]}
    />
  </Intersect>
);

const getCardGrid = ({
  type = 'simplified',
  cards = [],
  features = [],
  animated = false,
  id,
  transparentCard,
  zoomCard
}) => {
  const cardGridClassName = getCardGridClassName(cards.length)(type);
  return cards.length ? (
    <section className={cardGridClassName} key={id}>
      {cards.map((card, index) => {
        // simplifed has no feature
        const featured = type === 'simplified' ? false : includes(features, index + 1);
        return getCard({
          featured,
          card,
          animated,
          index,
          transparentCard,
          zoomCard
        });
      })}
    </section>
  ) : null;
};

const SpecialCardGrid = props => {
  const {
    cards,
    features = [],
    type = 'simplified',
    animated = false,
    transparentCard,
    zoomCard = false
  } = props;
  const extractedCards = extractCards(cards)(type);
  return (
    <>
      {extractedCards.map((data, index) =>
        getCardGrid({
          // second item is always simplifed grid
          type: index === 0 ? type : 'simplified',
          cards: data,
          features,
          animated,
          id: index,
          transparentCard,
          zoomCard
        })
      )}
    </>
  );
};

export default SpecialCardGrid;
